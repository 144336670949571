import React from 'react'
import styled from 'styled-components'
import { setup } from 'axios-cache-adapter'
import { motion } from 'framer-motion'
import { UIStore } from '../stores/UIStore'

type Props = {
  children: any
}

export const MainTextHolder = ({ children }: Props) => {
  return <MainText>{children}</MainText>
}

const MainText = styled.div`
  text-align: center;
  padding: 8px 22px 0 22px;

  > img {
    width: 100%;
  }

  @media (min-width: 769px) {
    max-width: 920px;
    margin: 0 auto;
    padding: 22px 22px 0;
  }

  h4 {
    margin-top: 10px;
    font-size: 17px;
  }

  > h4 {
    margin-top: 0;
  }

  figure {
    width: 100%;
    margin: 0;

    > img {
      width: 100%;
    }
  }
`

export const MainHolder = styled(motion.div)`
  width: 100vw;
  margin-top: 60px;

  h4 {
    text-transform: uppercase;
    letter-spacing: 0.46px;
  }

  strong {
    font-weight: 700;
  }

  p {
    margin-bottom: 20px;
    margin-top: 0;
  }

  @media (min-width: 769px) {
    max-width: 980px;
    margin: 0 auto;
  }
`

export const WideHolder = ({ children }: { children: any }) => (
  <StyledWideHolder
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 1 }}
  >
    {children}
  </StyledWideHolder>
)

const StyledWideHolder = styled(motion.div)`
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  margin-top: 60px;

  @media (min-width: 769px) {
    margin-top: 0;
  }
`

export const api = setup({
  baseURL: '//suabapi.engdahlmedia.se/wp-json/wp/v2/',
  cache: {
    maxAge: 15 * 60 * 1000,
  },
})

export function handleNavigation(page: string) {
  window.scrollTo(0, 0)
  UIStore.setMenuOpen(false)
  UIStore.setActivePage(page)
}

export const StyledImage = styled.img`
  width: 100%;
`

export const Spacer = styled.div`
  width: 100%;
`

export const getSlug = (link: string) => {
  if (link.substr(link.length - 1) === '/') {
    link = link.substr(0, link.length - 1)
  }
  link = link.substr(link.lastIndexOf('/'))

  return link
}

export const mailTo = (subject: string) => {
  window.location.href = `mailto:hyr@suab.nu?subject=Förfrågan om hyra av ${subject}`
}

export const SubPageHero = ({
  bgImage,
  children,
  title,
  color,
}: {
  bgImage: any
  children?: any
  title?: string
  color?: string
}) => (
  <StyledSubPageHero style={{ backgroundImage: `url(${bgImage})` }}>
    <h1 style={{ color: color }}>{title}</h1>
    <div
      dangerouslySetInnerHTML={{ __html: children }}
      style={{ color: color }}
    />
  </StyledSubPageHero>
)

const StyledSubPageHero = styled.div`
  width: 100%;
  max-width: 1400px;
  height: 300px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 0 60px;
  box-sizing: border-box;
  overflow: hidden;

  a {
    color: #f7b500;
    font-weight: 500;
  }

  h1 {
    text-transform: uppercase;
    font-size: 19px;
  }

  > div {
    max-width: 800px;

    p {
      margin: 0;
    }
  }
`

export const Loading = () => {
  return (
    <div
      style={{
        minHeight: 'calc(100vh - 150px)',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Spinner>
        <div className="rect1"></div>
        <div className="rect2"></div>
        <div className="rect3"></div>
        <div className="rect4"></div>
        <div className="rect5"></div>
      </Spinner>
    </div>
  )
}

export const scrollWithOffset = (el: any, offset: number, behavior?: any) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset
  const yOffset = -offset
  window.scrollTo({ top: yCoordinate + yOffset, behavior })
}

const Spinner = styled.div`
  margin: 100px auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;

  > div {
    background-color: #aaa;
    height: 100%;
    width: 6px;
    display: inline-block;
    margin: 2px;

    animation: sk-stretchdelay 1.2s infinite ease-in-out;

    &.rect2 {
      animation-delay: -1.1s;
    }

    &.rect3 {
      animation-delay: -1s;
    }

    &.rect4 {
      animation-delay: -0.9s;
    }

    &.rect5 {
      animation-delay: -0.8s;
    }
  }

  @keyframes sk-stretchdelay {
    0%,
    40%,
    100% {
      transform: scaleY(0.4);
      -webkit-transform: scaleY(0.6);
    }
    20% {
      transform: scaleY(1);
      -webkit-transform: scaleY(1);
    }
  }
`
